import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './App.css';

import { Provider } from 'react-redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { createStore, applyMiddleware, compose} from 'redux'
import { thunk } from 'redux-thunk'
import rootReducer from './reducers/index'
import storage from 'redux-persist/lib/storage'
import MainNavigation from './MainNavigation';
let store, persistor;
const App = () => {


  // const app = initializeApp(firebaseConfig);
  // let messaging =  getMessaging(app);

  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');
  // const [userData, setUserData] = useState({})

  // const [openOrders, setOpenOrders] = useState([]);
  // const navigate = useNavigate();
  // console.log('userData', userData);
  // useEffect(() => {
  //   // Fetch open orders when the component mounts
  //   if (loggedIn) {
  //     axios.post('https://localfood-badwaldsee.de/get_orders', {
  //       headers: {
  //         Authorization: userData.access_token
  //       }
  //     })
  //       .then(response => console.log('response', response))
  //       .catch(error => console.error(error));
  //   }
  // }, [loggedIn]);

  useEffect(() => {
    // Request permission for notifications

  }, []);

  // const handleOrders = () => {
  //   console.log('header', userData.access_token)
  //   axios.post('https://localfood-badwaldsee.de/open_orders', {},{
  //     headers: {
  //       Authorization: userData.access_token
  //     }
  //   })
  //     .then(response => console.log('response', response))
  //     .catch(error => console.error(error));
  // }

  const persistConfig = {
    key: 'root',
    whitelist: ['auth'],
    blacklist: ['order_reducer'],
    storage
  }
  
  const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

  store = createStore(persistedReducer, {}, 
    compose(
      applyMiddleware(thunk)
      )
    )
  persistor = persistStore(store);
store.subscribe(() => console.log('store', store.getState()));

  return (

    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <MainNavigation />
    </PersistGate>
  </Provider>


  );
};

export default App;

export {store, persistor};