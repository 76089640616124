import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { answer_order, get_open_orders, edit_order, set_order_done, remove_order, logout } from './actions';
import { Order } from './components/Order';
import { renew_token } from './actions/Utils';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './actions/config';
const NewOrder = ({
  access_token,
  answer_order,
  new_orders,
  get_open_orders,
  edit_order,
  set_order_done,
  remove_order,
  logout,
  refresh_token
}) => {

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();


  const handleOpenOrders = () => {
    get_open_orders()
    .then((data) => {
      console.log('handleOpenOrders', data);
      setLoading(false);
    })
    .catch((e) => {
      console.log('handleOpenOrders catch error', e);
      setLoading(false);
      // logout user in case of an error
      logout();
      
    })
  }

  useEffect(() => {
    setLoading(true);
    handleOpenOrders();
  }, []);

  const renderNoOrders = () => (
    <div className='bg-gray rounded-lg flex flex-col justify-center items-center mt-4'>
      <div className='bg-green rounded-full w-40 h-40 items-center justify-center flex'>
        <span className='text-3xl text-white'>✓</span>
      </div>
      <p className='text-black'>
        Du hast aktuell keine offenen Bestellungen!
      </p>
      <button className="p-3 bg-yellow text-black" onClick={() => handleOpenOrders()}>Neu Laden</button>
    </div>
  );
  const handleAnswer = (id, v, item) => {
    console.log('handleAnswer', id, v, item);
    answer_order(id, v, item);
  }
  const handleDone = (item) => {
    console.log('handleDone id', item.id);
    set_order_done(item.id);
  }
  const handleEdit = (id) => {
    console.log('handleEdit', id);
    edit_order(id);
  }

  return (
    <div className='bg-white flex-1 mt-10'>
      <div className='flex flex-col justify-center items-center'>
        {loading && <p>Loading...</p>}
        {!loading && (
          new_orders.map((item, index) => {
            console.log('item', item);
            return (
            <Order key={'order'+index} removable={true} {...item} onAnswer={(id, v) => handleAnswer(id, v, item)} removeOrder={remove_order} onEdit={(id) => handleEdit(id)} onDone={() => handleDone(item)}/>

            )
          }
        )
        )}
        {new_orders.length > 0 && (
        <button className="bg-yellow p-2 rounded-lg" onClick={() => get_open_orders()}>Neu Laden</button>

        )}
      </div>
 
      {new_orders.length === 0 && renderNoOrders()}
          <div className='text-black'>V1</div>
    </div>
  );
};

const mapStateToProps = ({order_reducer, auth}) => {
  const { new_orders, loading } = order_reducer;
  const { access_token, refresh_token } = auth;
  console.log('newOrder mapStateToProps access_token', access_token);
  console.log('new', new_orders);
  return { new_orders, access_token, loading, refresh_token };
}
export default connect(mapStateToProps, { answer_order, get_open_orders, edit_order, set_order_done, remove_order, logout })(NewOrder);

