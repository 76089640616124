import React from 'react';


const CancelItem = ({id, reason, onPress, active}) => {
    
    return (
        <button onClick={onPress} className={`${active ? 'border-red' : 'border-yellow'} border-2 p-2`}>
            <p className='text-white'>{reason}</p>
        </button>
    );
} 

export { CancelItem};