import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, redirect } from "react-router-dom";
import Login from './Login';
import NewOrder from './NewOrder';
import { connect } from 'react-redux';
import Orders from './Orders';
import Profile from './Profile';

const Navigation = () => (
    <nav className='w-full w h-20 flex flex-1 absolute mb-4'>
        <Link className="text-black" to="/">Aktuelle Bestellungen</Link>
        <Link className="text-black mx-2" to="/bestellungen">Alte Bestellungen</Link>
        <Link className="text-black" to="/profil">Profil</Link>


    </nav>
);


const MainNavigation = ({isLoggedIn}) => {

        console.log('MainNavigation loggedIn', isLoggedIn)
        if (isLoggedIn) {
            console.log('isLoggedIn', isLoggedIn);
        }

    return (


        <Router>
              {isLoggedIn && (
            <Navigation />
              )}
            <Routes >

                {!isLoggedIn ? (
                    <Route exact path="/" element={<Login />} />

                ) : (
                
                    <>
                    <Route path="/" element={<NewOrder />} />
                    {/* <Route path="/aktuell" element={<NewOrder />} /> */}
                    <Route path="/bestellungen" element={<Orders />} />
                    <Route path="/profil" element={<Profile />} />

                    </>
                )}



            </Routes>
        </Router>
    )
}
const mapStateToProps = ({ auth }) => {   

    console.log('MainNavigation mapStateToProps auth', auth);
    const { isLoggedIn } = auth;
   return { isLoggedIn}
}
export default connect(mapStateToProps , {})(MainNavigation);