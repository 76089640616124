import axios from 'axios';
import {
    GET_ORDERS,
    GET_ORDERS_FAIL,
    GET_ORDERS_SUCCESS,
    ANSWER_ORDER,
    ANSWER_ORDER_SUCCESS,
    ANSWER_ORDER_FAIL,
    NEW_ORDER,
    POLL_OPEN_ORDERS,
    POLL_OPEN_ORDERS_FAIL,
    POLL_OPEN_ORDERS_SUCCESS,
    EDIT_ORDER,
    SET_ORDER_DONE,
    SET_ORDER_DONE_FAIL,
    SET_ORDER_DONE_SUCCESS,
    REMOVE_ORDER,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAIL,
    LOGOUT_TOKEN
} from './types';

import { BASE_URL } from '../config';
import { fetch_data, refresh_token, handle_catch } from './Utils';
import axiosInstance from './config';

export const get_orders = () => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
    dispatch({
        type: GET_ORDERS,
    });
    axiosInstance.post('/get_orders')
        .then(({ data: { status, payload } }) => {
            console.log('status', status, payload);
            if (status === 0) {
                console.log('error');
                dispatch({
                    type: GET_ORDERS_FAIL,
                    payload: 'error'
                })
                reject('error');
            } else {
                dispatch({
                    type: GET_ORDERS_SUCCESS,
                    payload: payload
                });
                resolve(payload);
            }
        }).catch((e) => {
            console.log('catch error', e);
            reject(e);
        });
    })
    }
}


export const answer_order = (order_id, mins) => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: ANSWER_ORDER
            });
            const data = { 'order_id': order_id, 'mins': mins };
            axiosInstance.post('/answer_order', data)
                .then(({ data }) => {
                    const { status, payload } = data;
                    if (status == 1) {
                        console.log('successfully send answer', mins, order_id);
                        dispatch({
                            type: ANSWER_ORDER_SUCCESS,
                            payload: { mins, order_id }
                        });
                        resolve();
                    } else {
                        console.log('error');
                        dispatch({
                            type: ANSWER_ORDER_FAIL,
                            payload: payload
                        });
                        reject();
                    }
                }).catch((e) => {
                    reject(e);
                });
        })
    }
}

export const received_order = (order) => {
    console.log('received_order called')
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: NEW_ORDER,
                payload: order
            });
            resolve();
        });
    }

};

export const edit_order = (order_id) => {
    return {
        type: EDIT_ORDER,
        payload: order_id
    };
};

export const remove_order = (order_id) => {
    return {
        type: REMOVE_ORDER,
        payload: order_id
    };
};

const poll_it = (dispatch) => {

}
export const poll_open_orders = () => {

    return (dispatch) => {
        console.log('poll');
        poll_it(dispatch);
    }
}

export const get_open_orders = () => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: POLL_OPEN_ORDERS
            });
            axiosInstance.post('/open_orders')
                .then(({ data }) => {
                    const { status, payload } = data;
                    if (status == 1) {
                        dispatch({
                            type: POLL_OPEN_ORDERS_SUCCESS,
                            payload: payload
                        });
                        resolve(payload)
                    } else {
                        dispatch({
                            type: POLL_OPEN_ORDERS_FAIL,
                            payload: payload
                        });
                        reject(status)
                    }

                })
                .catch((e) => {
                    console.log('poll it catch', e);
                    reject(e);
                    // handle_catch(e, poll_it, [dispatch])
                });
        })
    }
}

export const set_order_done = (order_id) => {
    return (dispatch) => {
        dispatch({
            type: SET_ORDER_DONE
        });

        axiosInstance.post('/set_order_done', { order_id })
            .then(({ data }) => {
                const { status, payload } = data;
                if (status == 1) {
                    console.log('successfully got orders', payload);
                    dispatch({
                        type: SET_ORDER_DONE_SUCCESS,
                        payload: order_id
                    });
                } else {
                    console.log('error');
                    dispatch({
                        type: SET_ORDER_DONE_FAIL,
                        payload: payload
                    });
                }
            })
    }
}
// export const set_order_done = (order_id) => {
//     return (dispatch) => {
//         set_done(dispatch, order_id)
//     }
// }


// export const activate_demo_order = () => {
//     fetch_data('activate_demo')
//     .then(({data}) => {
//         const { success } = data;
//         if (success){
//             console.log('success');
//         }else{
//             console.log('no success');
//         }
//     })
// }