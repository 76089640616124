import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Order } from './components/Order';
import { get_orders } from './actions';
const Orders = ({ orders, get_orders }) => {

    useEffect(() => {
        console.log('orders', orders);
        get_orders();
    }, [])

    return (
        <div className='mt-4'>

            {
                orders.map((order, index) => {
                    return (
                        <Order key={'order' + index}  {...order} />
                    )
                })
            }
        </div>
    )
}
const mapStateToProps = ({ order_reducer }) => {
    const { orders } = order_reducer;
    return { orders };
}
export default connect(mapStateToProps, { get_orders})(Orders);