import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, UPDATE_FCM_TOKEN, RESET_ERROR, SET_ERROR, LOGGED_IN, LOGOUT } from './types';
// import * as Keychain from 'react-native-keychain';
import axios from 'axios';
import { BASE_URL } from '../config';

export const login = (username, pw, fcm_token) => {
    return (dispatch) => {
        return new Promise(function(resolve, reject) {
            dispatch({
                type: LOGIN
            });
            console.log('username', username);
            console.log('pw', pw);
            console.log('fcm_token', fcm_token);

            
            // if (authStatus != 1){
            //   dispatch({
            //     type: LOGIN_FAIL,
            //     payload: "Mitteilungen ('Push Nachrichten') müssen aktiviert werden um Bestellungen empfangen zu können."
            //   });
            //   return reject('error');
            // }
            if (username == "" || pw == ""){
              console.log('dispatch error');
              dispatch({
                type: LOGIN_FAIL,
                payload: "Nutzer und Passwort dürfen nicht leer sein."
              });
              return reject('error');
            }
            if (fcm_token == ""){
              console.log('dispatch error');
              dispatch({
                type: LOGIN_FAIL,
                payload: "Nutzer kann keine Bestellungen empfangen"
            });
              return reject('error');
            }
            axios({
                method: 'post',
                url: BASE_URL+'/login',
                data: { 'username': username, 'pw': pw, 'fcm_token': fcm_token},
                timeout: 20000,
              }).then(({data: {status, payload}}) => {
                console.log('login data', status, payload);
                if (status != 1) {
                  console.log('status not 1 error', payload);
                  dispatch({
                      type: LOGIN_FAIL,
                      payload: payload
                  });
                  return reject('error');
                }else{
                  //console.log('data', data);
                  const { access_token, refresh_token, super_user } = payload;
                  console.log('super_usaer', super_user);
                  if (access_token == "" || refresh_token == ""){
                    // error 
                  }
                  // //store credentials for easy login 
                  // Keychain.setInternetCredentials('org.tim.LocalFoodRes', username, password=pw, {
                  //   accessControl: 'BiometryAny',
                  //   securityLevel: Keychain.SECURITY_LEVEL.SECURE_SOFTWARE
                  // });
                  //store access tokens
                  // Keychain.setInternetCredentials('Local-Food-Restaurants_access_token', 'ACCESS_TOKEN', access_token);
                  // //store refresh tokens
                  // Keychain.setInternetCredentials('Local-Food-Restaurants_refresh_token', 'REFRESH_TOKEN', refresh_token);
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {access_token, refresh_token, super_user}
                    });
                    //Actions.newOrder();
                    return resolve();
                }
              }).catch((err) => {
                console.log('catch error', err);
                dispatch({
                  type: LOGIN_FAIL,
                  payload: "Es ist ein Fehler aufgetreten" + err
              });
              });
        });
       
    }
}

export const update_fcm_token = (token) => {
  return {
    type: UPDATE_FCM_TOKEN,
    payload: token
  };
};

export const reset_errors = () => {
  return {
    type: RESET_ERROR,
  };
};

export const set_error = (msg) => {
  return {
    type: SET_ERROR,
    payload: msg
  };
};

// export const set_logged_in = (value) => {
//   return {
//     type: LOGGED_IN,
//     payload: value
//   };
// };
export const logout = () => {
  // reset all credentials 
  // Keychain.resetInternetCredentials('org.tim.LocalFoodRes')
  // Keychain.resetInternetCredentials('Local-Food-Restaurants_access_token')
  // Keychain.resetInternetCredentials('Local-Food-Restaurants_refresh_token')
  return {
    type: LOGOUT,
  };
};
