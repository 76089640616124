import React from 'react';
import { connect } from 'react-redux';
import { logout } from './actions';
function Profile() {
    const handleLogout = () => {
        // Add your logout logic here
        logout()
    };

    return (
        <div className='mt-10 flex flex-col flex-1 justify-center items-center'>
            <p>Möchtest du dich ausloggen?</p>
            <button 
            className='bg-red text-white p-3 rounded-lg mt-4'
            onClick={handleLogout}>Logout</button>
        </div>
    );
}

export default connect(null, {logout})(Profile);