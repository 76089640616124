import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from 'firebase/messaging'
import { set_error, login } from './actions/AuthActions';
import { connect } from 'react-redux';

const Login = ({ set_error, login }) => {
  const firebaseConfig = {
    apiKey: "AIzaSyBok3LuGmK9zpw52oufS2940nUmWdntuXs",
    authDomain: "localfood-dab57.firebaseapp.com",
    databaseURL: "https://localfood-dab57.firebaseio.com",
    projectId: "localfood-dab57",
    storageBucket: "localfood-dab57.appspot.com",
    messagingSenderId: "222395676573",
    appId: "1:222395676573:web:274a3bac77516c015a2174",
    measurementId: "G-HQE33M2KJ7"
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [pushAllowed, setPushAllowed] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState({})
  const [fcmToken, setFcmToken] = useState('');
  const navigate = useNavigate()
  const app = initializeApp(firebaseConfig);
  let messaging = getMessaging(app);



  // useEffect(() => {
  //   // Request permission for notifications
  //   handlePush() 
  // }, []);

  
  const handleLogin = async () => {
    // Add authentication logic here (e.g., send a request to the backend for verification)
    // For simplicity, this example assumes successful login for any input

    // if (auth_status != 1 && !ignorePush) {
    //   messaging().requestPermission();
    //   this.setState({ routeToSettings: true });
    //   set_error('Mitteilungen ("Push Nachrichten") müssen aktiviert werden um Bestellungen empfangen zu können.')
    //   return
    // }
    if (username == '' || password == '') {
      alert('Bitte fülle alle Felder aus')
      return
    }
    const tmpToken = await handlePush();
    console.log('awaited', tmpToken)


    if (tmpToken === '') {
      alert('Bitte aktiviere Push Benachrichtigungen oder versuchen sie es gleich nochmal erneut')
      return
    }
    login(username, password, tmpToken)
      .then(() => {
        //navigate('aktuell')
      })
      .catch(e => console.log('login error', e));


    //   axios.post('https://localfood-badwaldsee.de/login', {
    //     username,
    //     pw: password,
    //     fcm_token: fcmToken
    //   }).then(({data}) => {
    //     const {payload} = data;
    //     // const {access_token, refresh_token, super_user} = payload;
    //     setUserData(payload)
    //     setLoggedIn(true);
    //     navigate('newOrder')
    //   } )
    //   .catch(error => console.log('catch error'))

    // };
  }

    const handleLogout = () => {
      // Add logout logic here (e.g., clear authentication tokens or session)
      setLoggedIn(false);
    };


    const handlePush = async () => {
      let permission = await Notification.requestPermission();
      console.log('persmission', permission)
      if (permission === "granted") {
        setPushAllowed(true)
        // Get the FCM token (see below)

        // Notification.requestPermission();
        const token = await getToken(messaging, { vapidKey: "BHEQXi8NI9cUHHuLPAvnz7h8aCwz_LxnOSuMW40vzrz6JYa6vqID_Zr1r78aSo9WhI4U5OZ5AooU4ymmyv0YNGA" })
        
        console.log('token', token)
        return token
      } else {
        // Handle denied permission
        return null
      }
    }


    return (
      <div className='bg-blue flex flex-1 flex-col align-center justify-center text-center'>
        <h1 className="text-white text-bold text-lg">Local Food Login</h1>
        {/* <button onClick={handlePush}>Request</button> */}
        <form className='flex-col flex items-center justify-center'>
          <label className='flex flex-col text-white text-start my-1'>
            Nutzer:
            <input className="text-black" placeholder='Dein Nutzername' type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </label>
        
          <label className='flex flex-col text-white text-start my-1'>
            Passwort:
            <input  className='text-black' placeholder="Dein Passwort" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </label>
          <br />
          <button className='bg-yellow p-3 rounded-sm w-44' type="button" onClick={handleLogin}>Login</button>
        </form>
      </div>
    )
  }

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn
  }
}


export default connect(mapStateToProps, { set_error, login })(Login);
