import AuthReducer from './AuthReducer';
import OrderReducer from './OrderReducer';
import MessagingReducer from './MessagingReducer';
import RestaurantReducer from './RestaurantReducer';

const rootReducer = {
    auth: AuthReducer,
    order_reducer: OrderReducer,
    msg: MessagingReducer,
    _restaurant: RestaurantReducer,
};

export default rootReducer;
