import {
    GET_RESTAURANT_BY_ID,
    GET_RESTAURANT_BY_ID_SUCCESS,
    GET_RESTAURANT_BY_ID_FAIL,
    SAVE_OPENING_HOURS,
    SAVE_OPENING_HOURS_SUCCESS,
    SAVE_OPENING_HOURS_FAIL,
    RESET_ERROR,
    CHANGE_OPENING_HOURS
} from '../actions/types';

const INITIAL_STATE = {
    restaurant: [],
    openingHours: [],
    loading: false,
    error: false,
    errorMsg: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_RESTAURANT_BY_ID:
            return { ...state, loading: true, error: false, errorMsg: ''};
        case GET_RESTAURANT_BY_ID_SUCCESS:
            return { ...state, loading: false, error: false, errorMsg: '', openingHours: action.payload.opening_hours, restaurant: action.payload.restaurant }; 
        case GET_RESTAURANT_BY_ID_FAIL:
            return { ...state, loading: false, error: true, errorMsg: action.payload};
        case SAVE_OPENING_HOURS:
            return { ...state, loading: true, error: false, errorMsg: ''};
        case SAVE_OPENING_HOURS_SUCCESS:
            console.log('openingHours', action.payload.opening_hours)
            return { ...state, loading: false, error: false, errorMsg: '', openingHours: action.payload.opening_hours};
        case SAVE_OPENING_HOURS_FAIL:
            return { ...state, loading: false, error: true, errorMsg: action.payload};
        case CHANGE_OPENING_HOURS:
            console.log('new opening hours', action.payload );
            state.openingHours.map((v,i)=> {
                console.log('v',v);
            })
            // return { ...state, openingHours: openingHours.map((v,i)=>{

            // }) };
            return { ...state };
        case RESET_ERROR:
            return { ...state, error: false, errorMsg: '' };
        
        default:
            return state;
    }
};