import {
    LOGIN_SUCCESS,
    LOGIN,
    LOGIN_FAIL,
    UPDATE_FCM_TOKEN,
    RESET_ERROR,
    SET_ERROR,
    LOGOUT_TOKEN,
    LOGGED_IN,
    LOGOUT,
} from '../actions/types';
import { REHYDRATE } from 'redux-persist';

const INITIAL_STATE = {
    access_token: '',
    refresh_token: '',
    super_user: false,
    loading: false,
    error: false,
    errorMsg: '',
    fcm_token: '',
    isLoggedIn: false,
    rehydrated: false, 
};

export default (state = INITIAL_STATE, action) => {
    console.log('action', action.type);
    switch (action.type) {
        case REHYDRATE:
            if (action.payload && action.payload.auth){
                console.log('rehydrate', action.payload.auth);
                return { ...state, ...action.payload.auth, rehydrated: true }; 
            }
            return { ...state };
        case LOGIN: 
            return { ...state, loading: true };
        case LOGIN_SUCCESS:
            console.log('login success', action.payload);
            return { ...state, loading: false, access_token: action.payload.access_token, refresh_token: action.payload.refresh_token, isLoggedIn: true, super_user: action.payload.super_user };
        case LOGIN_FAIL:
            return { ...state, loading: false, error: true, errorMsg: action.payload };
        case UPDATE_FCM_TOKEN:
            return { ...state, fcm_token: action.payload };
        case SET_ERROR:
            return { ...state, error: true, errorMsg: action.payload };
        case RESET_ERROR:
            return { ...state, error: false };
        case LOGOUT_TOKEN:
            return { ...INITIAL_STATE, error: true, errorMsg: action.payload };
        case LOGGED_IN:
             return { ...state, isLoggedIn: action.payload };
        case LOGOUT: 
            return { ...INITIAL_STATE };
        case "UPDATE_ACCESS_TOKEN":
            return {...state, access_token: action.payload}
        default:
            return state;
    }
};