import React, { useEffect, useState, useRef } from 'react';

// import Icon from 'react-native-vector-icons/FontAwesome5';
import { AnswerItem } from './AnswerItem';
import { CancelItem } from './CancelItem';
import checkIcon from '../assets/check-solid.svg';
import editIcon from '../assets/pen-solid.svg';

// import { DesiredTimeItem } from './DesiredTimeItem';

const answer_times = [15, 30, 45, 60, 75, 90];
const cancel_options = [
    { id: 1, reason: "Zu viele Bestellungen" },
    { id: 2, reason: "falsche Kundenangaben" },
    { id: 3, reason: "Produkt nicht verfügbar" },
];



const Item = ({ amount, name, price, total_price, added_extras }) => {
    const renderExtras = () => {
        return added_extras.map((v, i) => <p key={'extra' + i.toString()}>+ {v.name} {v.price}€</p>)
    }

    return (
        <div className='flex-row '>
            <div className='flex-col'>
                <span className='text-white'>{amount} x </span>
            </div>
            <div className='flex-row flex-1'>
                <p className='text-white'>{name}</p>
                {renderExtras()}
                <p className='text-white'>Preis: {total_price}€</p>
            </div>
        </div>
    );
}


const Order = ({ id, contact_name, number, address, phone, delivery, comment, zip_code, delivery_address, delivery_name, total_price, cart, estimated_time_delta, estimated_time, onAnswer, createdAt, order_done, edit_order, onEdit, onDone, res_name }) => {
    // console.log('edit_order', edit_order, 'onEdit', onEdit);
    //const order_done = done;


    //const [time, setTime ] = useState(estimated_time_delta > 0 ? estimated_time_delta : 0);
    //console.log('time', time);
    const prev_order_done = usePrevious(order_done);
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const renderItems = () => {
        return cart.map((v, i) => {
            return <Item {...v} key={'item' + i} />
        })
    }
const renderReasons = () => {
    if (estimated_time == 4) {
        //storno 
        return (
            <div >
                <p className='text-white'>Die Bestellung wurde automatisch auf Grund von Zeitüberschreitung geschlossen.</p>
            </div>
        );
    }
    return (
        <div className='flex-row text-white'>
            Für die Bestellung wurde <span className='text-white font-bold' >{cancel_options[estimated_time - 1].reason}</span> hinterlegt und an den Kunden übermittelt.
        </div>
    );
};
// const renderDesiredTime = () => {
//     if (desired_time) {
//         return (
//             <div>
//                 <p >Wunschzeit des Kunden:</p>
//                 {/* <DesiredTimeItem key={'answerOption wish'} label={`${Math.floor(desired_time / 60)}:${(desired_time % 60).toString().padStart(2,'0')}`} active={estimated_time == desired_time} onPress={()=> onAnswer(id, -1)}/> */}
//                 <div >
//                 <div />
//                 <p > Oder </p>
//                 <div />
//                 </div>
//             </div>
//         )
//     }
// }
const renderOptionsOrDone = () => {
    if (order_done) {
        // if (estimated_time_delta < 15) {
        //     //storno 
        //     return renderReasons()
        // }
        return (
            <div className='flex-row text-white'>
                Für die Bestellung wurden <span className='text-green'>{estimated_time} Minuten</span> hinterlegt und an den Kunden übermittelt.
            </div>
        );
    }
    if (estimated_time == 0 || edit_order) {
        console.log('show options')
        return (
            <>
                <p className='text-white'>Wie lange wird die Bestellung inkl. Lieferung benötigen?</p>
                {/* {renderDesiredTime()} */}
                <div >
                    {renderAnswerOptions(0)}
                </div>
                <div >
                    {renderAnswerOptions(1)}
                </div>
                <div >
                    {renderCancelOptions()}
                </div>
            </>
        );
    }
    if (estimated_time !== 0 && !edit_order && !order_done) {
        console.log('show done', estimated_time, edit_order, order_done)
        return renderDone();
    }
    // if (estimated_time_delta === 0 || edit_order) {
    //     console.log('return this')
    //     return (
    //         <>
    //             <p >Wie lange wird die Bestellung inkl. Lieferung benötigen?</p>
    //             {/* {renderDesiredTime()} */}
    //             <div className={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-around' }}>
    //                 {renderAnswerOptions(0)}
    //             </div>
    //             <div className={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-around' }}>
    //                 {renderAnswerOptions(1)}
    //             </div>
    //             <div className={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-around' }}>
    //                 {renderCancelOptions()}
    //             </div>
    //         </>
    //     );
    // }
    // if (estimated_time_delta == 0){
    //     return (
    //         <>
    //         <Text style={theme.p}>Wie lange wird die Bestellung inkl. Lieferung benötigen?</Text>
    //         <View style={{flexDirection: 'row', marginTop: 10, justifyContent: 'space-around'}}>
    //         {renderAnswerOptions(0)}
    //         </View>
    //         <View style={{flexDirection: 'row', marginTop: 10, justifyContent: 'space-around'}}>
    //         {renderAnswerOptions(1)}
    //         </View>
    //         <View style={{flexDirection: 'row', marginTop: 10, justifyContent: 'space-around'}}>
    //         {renderCancelOptions()}
    //         </View>
    //         </>
    //     );
    // }
}
const renderDone = () => {
    return (
        <div>
            {estimated_time >= 15 ? (
                <p className='text-white'>Für die Bestellung wurden <span className='font-bold text-green'>{estimated_time} Minuten</span> hinterlegt und an den Kunden übermittelt. </p>
            ) : renderReasons()}
            {/* <AnswerItem mins={time} active={true}/> */}
            <div style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
                <button onClick={onDone}>
                    <div className='flex flex-row w-36 justify-center h-12 border-green border-solid border-2 p-2 mr-2'>
                    {/* <div style={{ flexDirection: 'row', height: 50, width: 150, justifyContent: 'center' }}> */}
                        {/* <p>Erledigt</p> */}
                        {/* <Icon name="check" size={20} color='white' /> */}
                        <span className='text-white flex-1'>Erledigt</span>

                        <img src={checkIcon} className='w-6 h-6' alt='check-solid' />
                        {/* <img src='../../public/check-solid.svg' className='w-6 h-6' alt='check-solid' />  */}
                    </div>
                </button>
                <button onClick={() => onEdit(id)}>
                    <div className='flex flex-row w-36 h-12 items-center justify-center border-white border-2 p-2 border-solid'>
                        <span className='text-white flex-1'>Bearbeiten</span>
                        {/* <Icon name="pen" size={20} color='white' /> */}
                        <img src={editIcon} className='w-6 h-6 text-white flex-1' alt='check-solid' />

                    </div>
                </button>
            </div>
        </div>
    )
}
const renderAnswerOptions = (index) => {

    if (index == 0) {
        return answer_times.slice(0, 3).map((v) => {
            return <AnswerItem key={'answerOption' + v.toString()} mins={v} active={estimated_time == v} onPress={() => onAnswer(id, v)} />
        })
    }
    if (index == 1) {
        return answer_times.slice(3, 6).map((v) => {
            return <AnswerItem key={'answerOption' + v.toString()} mins={v} active={estimated_time == v} onPress={() => onAnswer(id, v)} />
        })
    }
}
const renderCancelOptions = () => {
    return cancel_options.map((v) => {
        console.log('cancelOption', v.id, estimated_time, v.id == estimated_time)
        return <CancelItem key={'cancelOption' + v.id.toString()} {...v} active={estimated_time == v.id} onPress={() => onAnswer(id, v.id)} />
    })
}
const renderStatus = () => {
    if (estimated_time == 4) {
        return (
            <div style={{ backgroundColor: '#D81159', width: 50, height: 50, borderWidth: 1, borderRadius: 25, borderColor: 'transparent', right: 0, top: -25, justifyContent: 'center', alignItems: 'center' }}>
                {/* <Icon name="hourglass-end" size={25} color={'white'} /> */}
            </div>
        );
    }
    if (estimated_time == 0) {
        return (
            <div style={{ backgroundColor: '#FFD685', width: 50, height: 50, borderWidth: 1, borderRadius: 25, borderColor: 'transparent', right: 0, top: -25, position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                {/* <Icon name="question" size={25} color={'white'} /> */}
            </div>
        );
    }
    if (estimated_time > 0) {
        return (
            <div style={{ backgroundColor: '#32936F', width: 50, height: 50, borderWidth: 1, borderRadius: 25, borderColor: 'transparent', right: 0, top: -25, position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
                {/* <Icon name="check" size={25} color={'white'} /> */}
            </div>
        );
    }
}

return (
    <div style={{ backgroundColor: '#2E4052', borderRadius: 10, borderWidth: 2, borderColor: '#2E4052', marginTop: 15, padding: 10, marginBottom: 15 }}>
        <div>
            <p className='text-white text-base font-bold'>Neue Bestellung: {id}</p>
            {delivery ? (<>
                <p className='text-white'>Lieferung an:</p>
                <p className='text-white'>{delivery_name}</p>
                <p className='text-white'>{address} {number}</p>
                <p className='text-white'>{zip_code}</p>
            </>) : (<>
                <p className='text-white'>Abholung von:</p>
                <p className='text-white'>{contact_name}</p>
                <p className='text-white'>{phone}</p>
            </>)}

        </div>
        {delivery ? (<div >
            <p className='text-white mt-3'>Bei Rückfragen:</p>
            <p className='text-white'>{contact_name}</p>
            <p className='text-white'>{phone}</p>
        </div>) : null}



        <div >
            <p className='text-white mt-3'>Anmerkung:</p>
            <p className='text-white'>{comment}</p>
        </div>

        <div >
            {renderItems()}
        </div>


        <p className='text-white'>-----</p>
        <p className='text-white'> Gesamtpreis: {total_price}€</p>

        <div style={{ marginTop: 10 }}>

            {renderOptionsOrDone()}
            <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <p className='text-white'>{res_name}</p>
                {/* <p>{createdAt.split('T')[1].split(':').slice(0, 2).join(':')}</p> */}
            </div>


        </div>
        {/* {renderStatus()} */}
    </div>
);
}

export { Order }; 