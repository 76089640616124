import {
    FCM_TOKEN_REFRESH,
    AUTH_CHANGED
} from '../actions/types';

const INITIAL_STATE = {
    auth_status: 0,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_CHANGED:
            console.log('AUTH_CHANGED', action.payload);
            return { ...state, auth_status: action.payload };
        default:
            return state;
    }
};