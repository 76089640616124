import {
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    GET_ORDERS,
    ANSWER_ORDER_FAIL,
    ANSWER_ORDER_SUCCESS,
    ANSWER_ORDER,
    NEW_ORDER,
    POLL_OPEN_ORDERS,
    POLL_OPEN_ORDERS_SUCCESS,
    POLL_OPEN_ORDERS_FAIL,
    EDIT_ORDER,
    SET_ORDER_DONE_FAIL,
    SET_ORDER_DONE_SUCCESS,
    SET_ORDER_DONE,
    REMOVE_ORDER,
    RESET_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    orders: [],
    new_orders: [],
    loading: false,
    error: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case GET_ORDERS: 
            return { ...state, loading: true };
        case GET_ORDERS_SUCCESS:
            return { ...state, loading: false, orders: action.payload };
        case GET_ORDERS_FAIL:
            return { ...state, loading: false, error: true };
        case NEW_ORDER:
            console.log('NEW_ORDER payload', action.payload);
            const parsed_json = JSON.parse(action.payload.cart);
            const new_data = { ... action.payload, cart: parsed_json, id:parseInt(action.payload.id) };
            console.log('new_data', new_data);

            const filtered = Object.values([...state.new_orders, new_data].reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}));
            console.log('filtered', filtered);
            return { ...state, new_orders: filtered};
        case ANSWER_ORDER: 
            return { ...state, loading: true };
        case ANSWER_ORDER_SUCCESS:
            return { ...state, loading: false, new_orders: [...state.new_orders.map((v,i) => {
                if (v.id == action.payload.order_id){
                    return { ...v, estimated_time: action.payload.mins, edit_order: false };
                }
                return { ...v };
            })] };
        case ANSWER_ORDER_FAIL:
            return { ...state, loading: false, error: true };
        case POLL_OPEN_ORDERS: 
            return { ...state, loading: true };
        case POLL_OPEN_ORDERS_SUCCESS:
            console.log('polling was successfull');
            return { ...state, loading: false, new_orders: action.payload };
        case POLL_OPEN_ORDERS_FAIL:
            return { ...state, loading: false, error: true };
        case EDIT_ORDER:
            return { ...state, loading: false, new_orders: [...state.new_orders.map((v,i) => {
                if (v.id == action.payload){
                    return { ...v, edit_order: true };
                }
                return { ...v };
            })] };
        case SET_ORDER_DONE:
            return { ...state, loading: false, error: false};
        case REMOVE_ORDER:
            return { ...state, loading: false, new_orders: [...state.new_orders.filter((v) => v.id != action.payload)] };
        case SET_ORDER_DONE_SUCCESS:
            return { ...state, loading: false, new_orders: [...state.new_orders.map((v,i) => {
                if (v.id == action.payload){
                    return { ...v, order_done: true };
                }
                return { ...v };
            })] };
        case SET_ORDER_DONE_FAIL:
                return { ...state, loading: false, error: true };
        case RESET_ERROR:
            return { ...state, error: false };
        default:
            return state;
    }
};