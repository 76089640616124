export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAIL = 'login_fail';

export const GET_ORDERS = 'get_orders';
export const GET_ORDERS_SUCCESS = 'get_orders_success';
export const GET_ORDERS_FAIL = 'get_orders_fail';

export const ANSWER_ORDER = 'answer_order';
export const ANSWER_ORDER_SUCCESS = 'answer_order_success';
export const ANSWER_ORDER_FAIL = 'answer_order_fail';

export const NEW_ORDER = 'new_order';

export const POLL_OPEN_ORDERS = 'poll_open_orders';
export const POLL_OPEN_ORDERS_SUCCESS = 'pull_open_orders_success';
export const POLL_OPEN_ORDERS_FAIL = 'pull_open_orders_fail';

export const EDIT_ORDER = 'edit_order';

export const SET_ORDER_DONE = 'set_order_done';
export const SET_ORDER_DONE_SUCCESS = 'set_order_done_success';
export const SET_ORDER_DONE_FAIL = 'set_order_done_fail';

export const REMOVE_ORDER = 'remove_order';

export const UPDATE_FCM_TOKEN = 'update_fcm_token';

export const RESET_ERROR = 'reset_error';
export const SET_ERROR = 'set_error';


export const AUTH_CHANGED = 'auth_changed';
export const FCM_TOKEN_REFRESH = 'fcm_token_refresh';


export const REFRESH_TOKEN = 'refresh_token';
export const REFRESH_TOKEN_SUCCESS = 'refresh_token_success';
export const REFRESH_TOKEN_FAIL = 'refresh_token_fail';


export const LOGOUT_TOKEN = 'LOGOUT_USER_TOKEN';
export const LOGOUT = 'logout';
export const LOGGED_IN = 'logged_in';


// Restaurant Update 

export const GET_RESTAURANT_BY_ID = "get_restaurant_by_id";
export const GET_RESTAURANT_BY_ID_SUCCESS = "get_restaurant_by_id_success";
export const GET_RESTAURANT_BY_ID_FAIL = "get_restaurant_by_id_fail";


export const SAVE_OPENING_HOURS = "save_opening_hours";
export const SAVE_OPENING_HOURS_SUCCESS = "save_opening_hours_success";
export const SAVE_OPENING_HOURS_FAIL = "save_opening_hours_fail";

export const CHANGE_OPENING_HOURS = 'change_opening_hours';
