import React from 'react';


const AnswerItem = ({mins, onPress, active}) => {
    return (
        <button onClick={onPress} className={`${active ? 'border-green border-1' : 'border-0' }  p-2 mx-2 my-2 bg-green`}>
        <p className='text-white'>{mins}min</p>
    </button>
    );
};

export {AnswerItem};