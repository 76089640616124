import axios from "axios";
import {store} from "../App";
import { BASE_URL } from "../config";
import { logout } from "./AuthActions";

const axiosInstance = axios.create({
    baseURL: "https://localfood-badwaldsee.de",
    timeout: 8000,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use(config => {
    console.log("Request sent", config);
    const token = store.getState().auth.access_token;
    if (token) {
        config.headers = {
            Authorization: `${token}`,
        };
    }
    return config;
});

const renew_token = async (refresh_token) => {
    console.log("renew_token", refresh_token);
    return new Promise((resolve, reject) => {
        axiosInstance.post(BASE_URL+"/refresh_it", { refresh_token })
            .then(({ data }) => {

                const { payload } = data;
                const { access_token } = payload;
                resolve(access_token);
            })
            .catch((e) => {
                console.log("renew_token error", e);
                reject('LOGOUT');
            });
    });
}

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        console.log("interceptors Error response", error);
        if (error.response.status === 401) {
            console.log("401 error");
            const refresh_token = store.getState().auth.refresh_token;
            console.log("refresh_token used", refresh_token);
            const access_token = await renew_token(refresh_token);

            store.dispatch({ type: "UPDATE_ACCESS_TOKEN", payload: access_token });
            console.log("access_token", access_token);
            originalRequest._retry = true;
            originalRequest.headers.Authorization = access_token;
            return axiosInstance.request(originalRequest);
            // handle 401 error
        }
        if (error.response.status === 400) {
            console.log("400 error");
            // handle 500 error
        }
        if (error.response.status === 403) {
            console.log("refresh it not found error");

            throw (error);

            // handle 500 error
        }
        throw error;
    }
);

export default axiosInstance;